@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

body,
html,
#root {
  display: flex;
  justify-content: center;
  font-family: "Poppins", serif;
  margin: 0;
  padding: 0;
  background-color: #141414;
  /* align-items: center; */
  width: 100%;
  height: 100vh;
  color: #FFFFFF;
}

.AppLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.AppLayout>*:not(.footer) {
  flex: 1;
  /* Pushes the footer to the bottom */
}



/* Profile =========================================================== */


.profile {
  text-align: center;
}

.profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 0;
}

.profile .level {
  position: absolute;
  display: inline-block;
  bottom: -10%;
  left: 38%;
  /* padding: 5px; */
  transform-origin: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  z-index: 1;
}

.profile .level::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 16px;
  min-height: 16px;
  background-color: #3D3D3B;
  border-radius: 2px;
  border: 1px solid #6B6B6B;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}


.profile .loading-line {
  width: 58px;
  height: 3px;
  background: #88888878;
  position: relative;
  overflow: hidden;
  border-radius: 1.5px;
  /* Rounded corners */
}

.profile .loaded {
  /* width: 0px; */
  /* Adjust this to the desired progress */
  height: 100%;
  background: #B4E201;
  transition: width 0.5s ease;
  /* Smooth animation when the width changes */
}

.profile .name {
  font-size: 12px;
  max-width: 100%;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



/* Home =========================================================== */

.aboutText {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  padding: 6px 8px 6px 8px;
  border-radius: 6px;
  border: 0.5px solid #FFFFFF4D
}



.token-logo {
  margin: 10px auto;
}

.main-stats {
  /* display: flex; */
  text-align: center;
  margin-top: 20px;
  /* justify-content: center; */
}

.main-stats .coin-icon {
  width: 36px;
  height: 36px;
}

/* .main-stats h2 {
  margin: 10px 0;
} */

.main-stats p {
  font-size: 12px;
  font-weight: 400;
  /* line-height: 18px; */
  margin: 0;
  text-align: center;
  color: #B1B1B1;
}

.main-stats span {
  color: #FFFFFF;
}

.tasks {
  text-align: center;
  margin: 20px 0;
  padding: 10px;
  width: 96%;
  height: 53px;
  border-radius: 12px;
  border: 0.5px solid #FFFFFFC9;
  background: #FFFFFF1A;
  backdrop-filter: blur(60px);
}

.tasks p {
  margin: 0;
}

.tasks .taskNumber {
  padding: 2px 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  border-radius: 100px;
  background-color: #FFFFFF;
  color: #142115;
}

.lucky-spin {
  margin: 20px;
  margin-top: -15%;
  height: 80px;
}

.lucky-spin .energy p {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
}

.lucky-spin .energy span {
  color: #FFFFFFA6;
}

.lucky-spin .spinBox {
  width: 78px;
  height: 74px;
  padding: 36px 6px 2px 6px;
  border-radius: 4px;
  border: 1px solid #FFFFFF1A;
  background: #FFFFFF1A;
  backdrop-filter: blur(60px);
  text-align: center;
}

.lucky-spin .spinBox p {
  font-size: 12px;
  font-weight: 500;
  line-height: 4px;
  text-align: center;
}

.lucky-spin .spinBox img {
  position: absolute;
  top: -90%;
  left: -19%;
}

.home .popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #141414;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1060;
  border-radius: 16px 16px 0 0;
  /* animation: slideUp 0.3s ease; */
}

.home .popup .close {
  position: absolute;
  top: 5%;
  right: 5%;
}

.home .popup .logo {
  width: 160px;
  margin: auto;
  margin-top: -25%;
  margin-bottom: -7%;
}

.home .popup h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.home .popup p {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #6B6B6B;
}

.home .popup .card {
  width: 100%;
  height: 37px;
  border-radius: 8px;
  background-color: #A3CC00;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
}

.home .popup .copy {
  width: 47px;
  height: 37px;
  border-radius: 8px;
  background-color: #A3CC00;
}



/* Footer =========================================================== */


.footer {
  position: fixed;
  bottom: 3%;
  width: 90%;
  /* margin: 2%; */
  height: 60px;
  border-radius: 16px;
  background-color: #FFFFFF1A;
  border: 0.5px solid #FFFFFFC9;
  backdrop-filter: blur(60px);
  padding: 10px 0;
}

/* .footer .active-nav {
  background: #FFFFFF59;
  border-radius: 12px;
  width: 100%;
  height: 100%;
} */


.footer p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.footer .nav-link {
  color: white;
  font-size: 18px;
}

.footer .nav-link.active {
  font-weight: bold;
}

.avatar {
  text-align: center;
}


.avatar .tap-image {
  margin-top: -30%;
  max-width: 220px;
  /* height: 80%; */
  cursor: pointer;
  transition: transform 0.1s ease;
}

.avatar .tap-image:active {
  transform: scale(0.9);
}

.avatar .plus {
  position: absolute;
  bottom: 0;
  /* Start position at the bottom of the avatar */
  /* left: 50%; */
  z-index: 10000;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  opacity: 0;
  font-size: 20px;
  font-weight: bold;
  color: white;
  /* animation: moveUpAndFade 1s forwards; */
  /* Animation duration and behavior */
}

.plus.animate {
  transform: translateY(-100px);
  opacity: 0.8;
}

/* @keyframes moveUpAndFade {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -30px);
  }
} */


.full-screen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.full-screen-menu .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.full-screen-menu .close-button:hover {
  background: #d32f2f;
}

.menu-content {
  text-align: center;
}

/* Pages =========================================================== */

.home {
  /* position: fixed; */
  width: 100%;
  /* min-height: 100vh; */
  height: 100vh;
  overflow-y: auto;
  background: linear-gradient(180deg, #141414 0%, #141414 12.15%, #124615 60.21%, #167C1C 100%);
  background-repeat: no-repeat;
  background-size: cover;
}

.games {
  /* position: fixed; */
  width: 100%;
  /* min-height: 100vh; */
  height: 100vh;
  overflow-y: auto;
  background: linear-gradient(180deg, #141414 0%, #141414 12.15%, #124615 60.21%, #167C1C 100%);
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  background-repeat: no-repeat;
  background-size: cover;
}

.earn,
.task,
.info,
.friends {
  /* position: fixed; */
  width: 100%;
  height: 85vh;
  /* Use 100vh for full viewport height */
  overflow-y: auto;
  z-index: 1050;
  /* Make the content inside scrollable */
  /* background-color: #141414; */
  background-repeat: no-repeat;
  background-size: cover;
}

/* Games =========================================================== */


.games .header h1 {
  margin-top: -15%;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.games .header p {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #D0D0D0;
}

.games .card {
  min-width: 120px;
  height: 104px;
  border-radius: 12px;
}

.games .submit {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: #A3CC00;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

/* App.css */
.wheel-container {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.wheel {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 4s ease-out;
}

.wheel-segment {
  border: 1px solid black;
}

.pointer {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid black;
  z-index: 1;
}

.spinning {
  /* Add any spinning specific styles here, if needed */
}

.games {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.results {
  text-align: center;
}

.text-danger {
  color: red;
}


/* Friends =========================================================== */


.friends .header h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  width: 200px;
}

.friends .giftImage {
  position: absolute;
  z-index: -1;
  top: 50px;
  right: 0;
}

.friends .inviteCard {
  width: 95%;
  height: 131px;
  border-radius: 12px;
  background-color: #2C2C2C;
}

.friends .inviteCard h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.friends .inviteCard .coin-icon {
  width: 12px;
  height: 12px;
}

.friends .inviteCard p {
  /* font-family: Gilroy-SemiBold; */
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.93px;
  text-align: center;
}

.friends .inviteCard span {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #D0D0D0;
}

.friends .inviteCard .card {
  width: 80%;
  height: 37px;
  border-radius: 8px;
  background-color: #A3CC00;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
}

.friends .inviteCard .copy {
  width: 37px;
  height: 37px;
  border-radius: 8px;
  background-color: #A3CC00;
}

.friends .friendCard {
  width: 95%;
  height: 80px;
  border-radius: 12px;
  background-color: #2C2C2C;
}

.friends .friendCard p {
  margin: 0;
}

.friends .friendCard img.profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.friends .friendCard .coinIcon {
  width: 12px;
  height: 12px;
}

.friends .friendCard .level {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 0;
  /* padding: 5px; */
  transform-origin: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  z-index: 1;
}

.friends .friendCard .level::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 25px;
  min-height: 25px;
  background-color: #3D3D3B;
  border-radius: 2px;
  border: 1px solid #6B6B6B;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}

.friends .friendCard .mining {
  font-size: 13px;
  max-width: 60%;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  background: #2F3032;
  padding: 4px 6px;
  border-radius: 6px;
}


/* Earn =========================================================== */

.earn {
  z-index: auto;
}

.earn .content h6 {
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: start;
  color: #AFAFAF;
}

.earn .content p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: start;
}

.earn .content img {
  width: 20px;
  height: 20px;
}

.earn h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.earn h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}

.earn .card {
  color: #FFFFFF;
  width: 100%;
  height: 80px;
  border-radius: 12px;
  background-color: #1D1E20;
}

.earn .card.daily {
  height: 90px;
}

.earn .card.daily.taken {
  border: 1px solid #A2CC00;
}

.earn .card.daily.taken .done {
  position: absolute;
  top: -5%;
  right: -5%;
}

.earn .timer {
  width: 100%;
  height: 42px;
  border-radius: 12px;
  background-color: #1D1E20;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #979797;
  text-align: center;
}

.earn .timer.active {
  background-color: #A2CC00;
  color: #1D1E20;
}

.earn .card .card-body .daysCount {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.earn .card .price {
  font-size: 11px;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
  background-color: #A2CC00;
  padding: 4px 10px;
  border-radius: 100px;
}

.earn .card .card-body img {
  width: 22px;
  height: 22px;
}

.earn .card p {
  margin: 0;
}

.earn .card h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
}

.earn .card .count {
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  background-color: #A2CC00;
  padding: 4px 10px;
  border-radius: 100px;
}

.earn .card .energyIcon {
  width: 13px;
  height: 13px;
}

.earn .card .level {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  background-color: #2F3032;
  padding: 4px 6px;
  border-radius: 6px;
}

.earn .card .status {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #7A7A7A;
}

.earn .card .iconBox {
  position: relative;
  width: 61px;
  height: 61px;
  border-radius: 6px;
  background-color: #2F3032;
}

.earn .card p.increment {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #F8F000;
}

.earn .card .locked p {
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: center;
  color: #949494;
}

.earn .card .locked img {
  width: 13px;
}

.earn .card .iconBox img {
  margin: auto;
}

.earn .card .iconBox .miningIcon {
  width: 40px;
  height: 40px;
}

.earn .card .iconBox img.battery {
  position: absolute;
  left: -40%;
  top: -40%;
}

.earn .card .infoCard {
  width: 93px;
  height: 36px;
  border-radius: 8px;
  background-color: #2F3032;
}

.earn .card .infoCard img {
  width: 14px;
  height: 14px;
}

.earn .card .infoCard.active {
  background-color: #A2CC00;
}

.vertical-line {
  border: 1px solid #FFFFFF0D;
  height: 90%;
}


.earn .nav-tab {
  background: #383838;
  border-radius: 6px;
  width: 94%;
  height: 46px;
  display: inline-flex;
  overflow: hidden;
}

.earn .nav-tab button {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  border: none;
  background: #383838;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
  z-index: 1;
  /* Ensure text stays on top */
}

.earn .nav-tab button.active {
  color: #383838;
}

.earn .nav-tab button.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  /* Adjust the width of the rectangle */
  height: 36px;
  /* Adjust the height of the rectangle */
  background-color: #b4f01e;
  /* Active background color */
  border-radius: 6px;
  /* Optional: Rounded corners for the rectangle */
  z-index: -1;
  /* Rectangle behind the text */
}

.earn .nav-tab button:focus {
  outline: none;
}


/* Dark overlay background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

/* Popup container */
.earn .popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: radial-gradient(145.33% 91.44% at 50% 5.62%, #0BBC15 0%, #167C1C 21.51%, #124615 41.22%, #141414 68.76%, #141317 100%);
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1060;
  border-radius: 16px 16px 0 0;
  animation: slideUp 0.3s ease;
}

.earn .popup h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.earn .popup p {
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: center;
}

.earn .popup .miningIcon {
  height: 100px;
}

.earn .popup p.secondary {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #BFBFBF;
}

.earn .popup .card {
  width: 60%;
  height: 52px;
  border-radius: 12px;
}

.earn .popup .card.big {
  width: 100%;
  height: 82px;
  border-radius: 12px;
}

.earn .popup .card.big img {
  width: 12px;
  height: 12px;
}

.earn .popup .level-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.earn .popup .level {
  text-align: center;
}

.earn .popup .level img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.earn .popup .progress-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
}

.earn .popup .progress-bar {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.earn .popup .progress-point {
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  z-index: 1;
}

.earn .popup .progress-point.active {
  background: gold;
}


.earn .popup .submit {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: #A3CC00;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.earn .popup .submit img {
  width: 16px;
  height: 16px;
}

/* Slide-in animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}


/* Info =========================================================== */

.info .card h2,
p {
  color: #FFFFFF;
}

.info .card {
  width: 100%;
  height: 104px;
  border-radius: 12px;
  background-color: #1D1E20;
}

.info .card.settings {
  height: 52px;
}

.info .card.settings .icon {
  width: 32px;
  height: 32px;
}

.info .card .arrow {
  width: 5px;
  height: 10px;
}

.info .copyright {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #535353;
  position: fixed;
  bottom: 0;
  padding: 2%;
  margin-right: 2%;
}

.info .card.settings h3 {
  margin: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.info .card img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.info .card.sound {
  height: 106px;
}

.info .card h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}


.info .level {
  position: absolute;
  display: inline-block;
  bottom: -10%;
  left: 38%;
  /* padding: 5px; */
  transform-origin: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  z-index: 1;
}

.info .level::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 16px;
  min-height: 16px;
  background-color: #3D3D3B;
  border-radius: 2px;
  border: 1px solid #6B6B6B;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}


.info .loading-line {
  width: 100%;
  height: 3px;
  background: #88888878;
  position: relative;
  /* overflow: hidden; */
  border-radius: 1.5px;
  /* Rounded corners */
}

.info .loaded {
  width: 40%;
  /* Adjust this to the desired progress */
  height: 100%;
  background: #B4E201;
  transition: width 0.5s ease;
  /* Smooth animation when the width changes */
}

.info .currentLanguage {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #C8C8C8;
  margin: 0;
  /* text-align: left; */
}

.info .popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #1D1E20;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  border-radius: 12px 12px 0 0;
  animation: slideUp 0.3s ease;
}

.info .popup h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.info .popup .selected {
  width: 19px;
  height: 19px;
}


/* Task =========================================================== */

.task {
  height: 100vh;
}

.task .popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: radial-gradient(145.33% 91.44% at 50% 5.62%, #0BBC15 0%, #167C1C 21.51%, #124615 41.22%, #141414 68.76%, #141317 100%);
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1060;
  border-radius: 16px 16px 0 0;
  animation: slideUp 0.3s ease;
}

.task .popup .close {
  position: absolute;
  top: 5%;
  right: 5%;
}

.task .popup .logo {
  width: 40%;
}

.task .popup h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.task .popup .price {
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
  color: #F2BC1A;
}

.task .popup .submit {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: #A3CC00;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}


.task .allTasks {
  background-color: #1D1E20;
  border-radius: 12px;
  width: 90%;
  height: auto;
}

.task .allTasks h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.task .allTasks p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.93px;
  color: #E9B93B;
}

.task .allTasks .coinIcon {
  width: 14px;
  height: 14px;
}

.task .allTasks .arrow {
  width: 5px;
  height: 10px;
}

.full-width-line {
  border: 1px solid #FFFFFF1A;
  margin: 0;
}

.full-width-line.secondary {
  border: 1px solid rgba(121, 121, 121, 0.1);
}


.slider-container {
  width: 27px;
  height: 11px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  /* Centers the circle vertically */
  padding: 2px;
  /* Ensures the circle fits perfectly inside */
  box-sizing: border-box;
  /* Includes padding in the width/height calculations */
}

.slider-container.off {
  background-color: #A7A7A74D;
}

.slider-container.on {
  background: rgba(203, 255, 0, 0.5);
}

.slider-circle {
  width: 16px;
  /* Should be slightly smaller than the slider height */
  height: 16px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional for a nice effect */
}

.slider-circle.on {
  background-color: #CBFF00;
}

.slider-container.on .slider-circle {
  transform: translateX(10px);
}