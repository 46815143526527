* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #34495e;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.title h4 {
    color: #A3CC00;
    font-size: 14px;
    font-weight: 600;
    line-height: 10px;
    text-align: center;
}

.title h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.spin-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spin-arrow {
    position: absolute;
    top: -3%;
    z-index: 2;
    /* Adjusted for smaller size */
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
}

.spin-arrow::before {
    content: "\1F817";
    font-size: 25px;
    /* Half of original 50px */
}

#spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #e2e2e2;
    text-transform: uppercase;
    border: 4px solid #fff;
    background-color: #A3CC00;
    /* Half of original 8px */
    font-weight: bold;
    font-size: 10px;
    /* Half of original 20px */
    color: #FFFFFF;
    width: 70px;
    /* Half of original 80px */
    height: 70px;
    font-family: sans-serif;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    letter-spacing: 0.5px;
}

.longSpinButton {
    background-color: #A3CC00;
    width: 90%;
    min-height: 44px;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.results {
    min-height: 90px;
}